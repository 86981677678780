<template>
  <div>
    <sit-block-card search="" />
  </div>
</template>
<script>
import SitBlockCard from "../components/SitBlockCard.vue";
export default {
  components: { SitBlockCard },
};
</script>
